import {
  attendanceColorCodes,
  ATTENDANCE_GOOD,
  ATTENDANCE_OK,
} from '@src/constants/attandance/attandance-constant.js'

export default {
  methods: {
    calculatePresentRate(presentRate, AbsentRase) {
      if (presentRate > 0 || AbsentRase > 0) {
        if (presentRate > ATTENDANCE_GOOD) return { backgroundColor: attendanceColorCodes.PRESENT }
        else if (presentRate >= ATTENDANCE_OK && presentRate <= ATTENDANCE_GOOD)
          return { backgroundColor: attendanceColorCodes.DELAY }
        else return { backgroundColor: attendanceColorCodes.ABSENT }
      } else
        return {
          border: '1px solid #e4e5ec',
          color: '#1d273e',
        }
    },
  },
}
