<template>
  <section class="schedule-responsive border border-primary-grey rounded-xl">
    <div v-if="!$slots.noEvent" class="w-full bg-primary-white overflow-hidden">
      <div class="schedular__head w-full">
        <ul class="flex items-center">
          <li
            class="py-4 border-b border-primary-grey font-inter font-medium text-text-color text-sm capitalize box-border"
          >
            <p
              v-i18n="attendanceTranslation"
              class="py-1 ltr:pl-3 ltr:pr-6 rtl:pr-3 rtl:pl-7 ltr:border-r rtl:border-l border-primary-grey"
            >
              Timing
            </p>
          </li>
          <li
            v-for="(head, index) in tableHead"
            :key="head"
            class="day-heading py-4 border-b border-primary-grey font-inter font-medium text-text-color text-sm capitalize box-border flex justify-center"
          >
            <p
              :class="{ 'border-r border-primary-grey': index !== tableHead.length - 1 }"
              class="py-1 px-3"
            >
              {{ $t(`attendanceTranslation.${head}`) }}
            </p>
          </li>
        </ul>
      </div>
      <div class="schedular__body relative">
        <ul v-for="time in tableTimeRange" :key="time" class="flex items-center">
          <li
            :style="{ height: `${134}px`, width: `${80}px` }"
            class="flex justify-center border-b ltr:border-r rtl:border-l border-primary-grey border-box font-inter font-normal text-text-color text-sm uppercase"
          >
            <p class="pt-3">{{ time }}</p>
          </li>
          <li
            v-for="(head, index) in tableHead"
            :key="head"
            :style="{ height: `${134}px`, width: `${560}px` }"
            :class="{ 'border-r': index !== tableHead.length - 1 }"
            class="flex items-center flex-1 border-b border-primary-grey border-box"
          ></li>
        </ul>
        <ul v-if="isPeriodFromMultipleDays" class="flex items-center">
          <li
            :style="{ height: `${134}px`, width: `${80}px` }"
            class="flex justify-center border-b ltr:border-r rtl:border-l border-primary-grey border-box font-inter font-normal text-text-color text-sm uppercase"
          ></li>
          <li
            v-for="(head, index) in tableHead"
            :key="head"
            :style="{ height: `${134}px`, width: `${560}px` }"
            :class="{ 'border-r': index !== tableHead.length - 1 }"
            class="flex items-center flex-1 border-b border-primary-grey border-box"
          ></li>
        </ul>

        <Event
          v-for="(eventObj, idx) in dayEvent"
          :key="eventObj.id + idx"
          :table-time="tableTime"
          :table-head="tableHead"
          :event="eventObj"
          :day-event="true"
          :hide-overlap="eventObj.isOverlapped === undefined ? hideOverlap : !eventObj.isOverlapped"
          :hide-actions="hideActions"
          @eventClicked="$emit('viewEvent', eventObj.id)"
        >
          <template v-slot="{ event, height }">
            <p
              class="capitalize truncate text-xs font-inter"
              :style="{
                color: getEventColor(eventObj.subject.color, 600, 'white'),
              }"
            >
              {{ event.title }}
            </p>
            <div v-if="height > 105">
              <p
                class="truncate text-10 text-primary-gray-500 font-inter font-normal"
                :style="{
                  color: getEventColor(eventObj.subject.color, _, 'white'),
                }"
              >
                <span v-i18n="dashboard">Class</span>
                : {{ (event && event.class) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </p>
              <p
                class="truncate text-10 text-primary-gray-500 font-inter font-normal"
                :style="{
                  color: getEventColor(eventObj.subject.color, _, 'white'),
                }"
              >
                <span v-i18n="dashboard">Teacher</span>
                :
                <span>{{ event.teacher || GENERAL_CONSTANTS.NOT_APPLICABLE }}</span>
              </p>
            </div>
            <div
              v-else
              class="truncate text-10 text-primary-gray-600 font-inter font-medium cursor-pointer mt-auto"
              :style="{
                color: getEventColor(eventObj.subject.color, _, 'white'),
              }"
            >
              <span v-i18n="dashboard">View More</span>
            </div>
          </template>
          <template v-slot:actions>
            <ul v-if="!hideActions" class="py-1 actions-list">
              <li
                v-if="actions.includes('edit')"
                v-i18n="dashboard"
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="editEvent($event, eventObj)"
              >
                Edit
              </li>
              <li
                v-if="actions.includes('delete')"
                v-i18n="attendanceTranslation"
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="deleteEvent($event, eventObj)"
              >
                DS_DLT_TABLE_ACTION
              </li>
            </ul>
          </template>
        </Event>
      </div>
    </div>
    <slot name="noEvent"></slot>
  </section>
</template>

<script>
import Event from '@components/UiElements/Scheduler/Event.vue'
import { tableSide, getEventColor } from '@src/utils/timeTableUtils.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import timeMixin from '@src/mixins/timeMixin'

export default {
  name: 'Schedule',
  components: {
    Event,
  },
  mixins: [timeMixin],

  props: {
    theads: {
      type: Array,
      default: () => ['monday'],
    },
    tableTime: {
      type: Array,
      default: () => ['10:00 am', '4:00 pm'],
    },
    events: {
      type: Array,
      default: () => [],
    },
    hideOverlap: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => ['edit', 'delete'],
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['editEvent', 'deleteEvent', 'viewEvent'],
  data() {
    return {
      GENERAL_CONSTANTS,
      tHeadLable: this.theads,
      dayEvent: null,
      attendanceTranslation: 'attendanceTranslation',
      dashboard: 'dashboard',
      tableTimeRange: [],
    }
  },
  computed: {
    tableHead() {
      const startIdx = this.tHeadLable.indexOf(this.theads[0])
      const endIdx = this.tHeadLable.indexOf(this.theads[1])

      let tableHeadTemp = []
      if (startIdx > endIdx) {
        tableHeadTemp = this.tHeadLable.slice(startIdx)
        tableHeadTemp = tableHeadTemp.concat(this.tHeadLable.slice(0, endIdx + 1))
      } else {
        tableHeadTemp = this.tHeadLable.slice(startIdx, endIdx + 1)
      }
      return tableHeadTemp
    },
    isPeriodFromMultipleDays() {
      return this.tableTimeRange.includes('11:00 pm') || this.tableTimeRange.includes('11:00 PM')
    },
  },

  watch: {
    events: {
      immediate: true,
      deep: true,
      handler: 'initializeDayEvents',
    },
    tableTime: {
      immediate: true,
      handler() {
        this.setTableSide()
      },
    },
  },

  methods: {
    getEventColor,
    setTableSide() {
      this.tableTimeRange = tableSide(this.tableTime)
    },
    initializeDayEvents() {
      this.dayEvent = this.events.filter((event) => event.day === this.theads[0].toLowerCase())
    },

    editEvent(e, event) {
      e.stopPropagation()
      this.$emit('editEvent', event)
    },
    deleteEvent(e, event) {
      e.stopPropagation()
      this.$emit('deleteEvent', event)
    },
  },
}
</script>

<style lang="scss">
.schedular {
  width: fit-content;
  .schedular__head,
  .schedular__body {
    width: fit-content;
  }
  .schedular__body {
    ul.flex.items-center::after {
      position: absolute;
      width: 100%;
      height: 0;
      content: '';
      border-top: 1px dotted theme('colors.bg-light');
    }
  }
}
</style>

<style lang="scss" scoped>
.day-heading {
  width: 100%;
}
</style>
