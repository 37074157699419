<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="Schedule"
      :display-breadcrumb="true"
      @applyFilter="applyFilter"
      @resetFilter="resetFilter"
    ></TitleWrapper>
    <div
      class="minHieght100 main-content bg-primary-white rounded-md mt-5 p-5"
      :class="!isLoading && isEmpty(events) ? 'border-0' : 'border-border-color-lighter border'"
    >
      <CalendarActionsWrapper
        :calendar-date="
          filters.filterBy === GENERAL_CONSTANTS.WEEK ? dateRange : dateRange.startDate
        "
        :filter-view="filters.filterBy"
        :filters="['Month', 'Week', 'Day']"
        @selectDate="setDate"
        @selectDateRange="setDateRange"
        @setFilter="setFilter"
        @applyFilter="applyFilter"
      ></CalendarActionsWrapper>
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else>
        <UiCalendar
          v-if="filters.filterBy === GENERAL_CONSTANTS.MONTH"
          :key="forceReRender"
          :calendar-date="dateRange.startDate"
        >
          <!-- change the way of passing Dynamic Slot Names -->
          <template v-for="(event, index) in events" v-slot:[`day-${getDay(event.date)}`]>
            <slot :name="``">
              <div v-if="Object.prototype.hasOwnProperty.call(event, 'isAction')" :key="index">
                <div
                  v-if="!event.isAction"
                  class="time-schedule flex cursor-pointer justify-center items-center text_size whitespace-nowrap gap-0.5 text-xs"
                  @click="singleRecord(event)"
                >
                  <p
                    class="flex-shrink-0 dot dot_size h-2 w-2 rounded-full"
                    :style="{ backgroundColor: event.subject.color }"
                  ></p>
                  <div
                    class="text-white px-1 mb-0.5 rounded-sm"
                    :style="
                      calculatePresentRate(
                        event.attendanceMeta.present,
                        event.attendanceMeta.absent,
                      )
                    "
                  >
                    {{ `${localTime(event.startTime, true)} - ${localTime(event.endTime, true)}` }}
                  </div>
                </div>
                <div v-if="event.isAction" class="time-schedule viewMore">
                  <p
                    class="font-roboto font-medium text-primary-grey-light cursor-pointer viewMoreText"
                    @click="clickDay(event.date)"
                  >
                    {{ $t(`schedule.View More`) + ' ...' }}
                  </p>
                </div>
              </div>
            </slot>
          </template>
        </UiCalendar>
        <ScheduleWeeklyView
          v-if="filters.filterBy === GENERAL_CONSTANTS.WEEK"
          :theads="dayRange"
          :table-time="[timeRange[0] || '09:00 am', timeRange[1] || '05:00 pm']"
          :events="events"
          :actions="['edit', 'delete']"
          @viewEvent="viewEvent"
          @editEvent="editEvent"
          @deleteEvent="deleteEvent"
        ></ScheduleWeeklyView>
        <DaySchedule
          v-if="filters.filterBy === GENERAL_CONSTANTS.DAY"
          :key="currentDay"
          :theads="[currentDay]"
          :t-time="[timeRange[0] || '09:00 am', timeRange[1] || '05:00 pm']"
          :events="events"
          @viewEvent="viewEvent"
          @editEvent="editEvent"
          @deleteEvent="deleteEvent"
        ></DaySchedule>
      </div>
    </div>

    <RoutineModal
      footer-classes="flex justify-end"
      :modal-show="routinesDetailModal"
      :modal-width="40"
      @handleClick="handleRoutinesDetailClick"
    >
      <template v-slot:header>
        <span v-i18n="schedule">PER_DETAILS</span>
      </template>
      <template v-slot>
        <div
          v-for="(sch, idx) in detailsEventsArr"
          :key="sch.id"
          class="px-4 md:px-8"
          :class="{ 'mb-8': idx === detailsEventsArr.length - 1 }"
        >
          <div class="flex flex-col gap-5 sm:flex-row mt-6">
            <span class="text-2xl text-label-text font-bold">{{ sch.title }}</span>
            <span class="subjectColor"></span>
          </div>
          <div class="flex flex-row mt-5 gap-3 md:gap-7">
            <div class="flex flex-1 gap-5 flex-col">
              <div class="flex items-center">
                <Icon icon="calender" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ formatDateString(sch.date) || sch.day }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Class</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.class }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Subject</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.subject.title }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="title">Lesson</span>
                :
                <span v-if="sch.lesson" class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.lesson.title }}
                </span>
              </div>
            </div>
            <div class="flex gap-5 flex-1 flex-col text-left">
              <div class="flex clock-icon whitespace-nowrap items-center">
                <Icon icon="clock" color="primary-green" height="21" width="18" />
                <span class="px-2 md:px-3 text-xs md:text-base text-primary-grey-light">
                  {{ localTime(sch.startTime) }} - {{ localTime(sch.endTime) }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Teacher</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.teacher }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Room</span>
                :
                <span class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.room }}
                </span>
              </div>
              <div class="text-xs md:text-base text-label-text font-bold rtl:text-right">
                <span v-i18n="schedule">Lesson Type</span>
                :
                <span v-if="sch.lesson" class="md:px-3 text-primary-grey-light font-normal">
                  {{ sch.lesson.type }}
                </span>
              </div>
            </div>
          </div>
          <hr
            v-if="detailsEventsArr.length > 1 && idx < detailsEventsArr.length - 1"
            class="my-5"
          />
        </div>
      </template>
    </RoutineModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { groupBy, isEmpty } from 'lodash'

import UiCalendar from '@components/Calendar/index.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import RoutineModal from '@components/UiElements/UiModalContainer.vue'
import Icon from '@components/icons/icon.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import ScheduleWeeklyView from '@components/UiElements/Scheduler/Schedule.vue'
import DaySchedule from '@views/time-table/current-time-table/PeriodDailyView.vue'
import { formatDateString } from '@utils/format-date'
// Mixin
import timeMixin from '@src/mixins/timeMixin'
import timeTableMixin from '@src/mixins/components/timetable-mixin'
import { getSubjectEventUtc, getTimeRange, getEventsWithNonOverlap } from '@utils/timeTableUtils'
import GENERAL_CONSTANTS, { weekDays, MONTHS } from '@src/constants/general-constants.js'
import DATE_TIME from '@src/constants/date-time-constants.js'
export default {
  name: 'Schedule',
  components: {
    TitleWrapper,
    Loader,
    ScheduleWeeklyView,
    Icon,
    CalendarActionsWrapper,
    RoutineModal,
    DaySchedule,
    UiCalendar,
  },
  mixins: [timeMixin, timeTableMixin],
  data() {
    return this.initialState()
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope.id,
    }),
    schedule() {
      return 'schedule'
    },
  },

  watch: {
    events: {
      deep: true,
      immediate: true,
      handler() {
        const temp = getTimeRange(this.events)

        this.timeRange.splice(0, 1, temp[0])
        this.timeRange.splice(1, 1, temp[1])
      },
    },

    classId: {
      deep: true,
      handler() {
        if (this.classId) {
          this.loadFilterData()
          this.setDayRange(this.dateRange)
          this.applyFilter()
        } else this.resetComponent()
      },
    },
  },

  created() {
    /*
     * Calculate current month date range
     */
    const startDate = new Date()
    const endDate = new Date()

    this.dateRange.startDate = startDate

    endDate.setDate(endDate.getDate() + 6)

    this.dateRange = {
      startDate,
      endDate,
    }
    /*
     * Set Static Rightbar Data untill APIs status in pending or failed
     */
    this.setRightbarContent({
      header: {
        title: 'Schedule',
        buttons: [
          {
            title: 'Add Schedule',
            classes: ['primary-button-right'],
          },
        ],
      },
      stats: [
        {
          title: 'Subject',
          categories: [
            { text: 'Total Subject', value: '--' },
            { text: `Todays Subject`, value: '--' },
          ],
        },
      ],
    })
    /*
     * Called aaplyFilter funnction to load schedules of current month when component is created
     * Load schedule filtered Data for current section
     * Set Day Range in weekly view
     */
    if (this.classId) {
      this.loadFilterData()
      this.setDayRange(this.dateRange)
      this.applyFilter()
    }
  },

  beforeUnmount() {
    this.viewEvent({})
  },

  methods: {
    formatDateString,
    initialState() {
      return {
        isEmpty,
        GENERAL_CONSTANTS,
        isLoading: false,
        title: 'title',
        loadingAddNewRoutine: false,
        forceReRender: 1,
        rightBarSubjects: [],
        routineInfo: {
          classes: [],
          subjects: [],
          teachers: [],
          rooms: [],
          days: weekDays,
        },
        routineData: {
          day: '',
          start_time: null,
          end_time: null,
          room_id: null,
          subject_id: null,
          section_id: '',
          teacher_id: null,
          is_active: true,
          // date: new Date(),
        },
        filters: {
          filterBy: GENERAL_CONSTANTS.MONTH,
        },

        routineFilter: {
          teacher: '',
          subject: '',
          room: '',
        },

        routineFilterData: {
          teachers: [],
          subjects: [],
          rooms: [],
        },

        dateRange: {},
        dayRange: GENERAL_CONSTANTS.DAY_RANGE,
        timeRange: GENERAL_CONSTANTS.TIME_RANGE,
        events: [],
        currentDay: GENERAL_CONSTANTS.WEEK_START_DAY,
        respDataObjDump: null,
        routinesDetailModal: false,
        detailsEventsArr: [],
      }
    },

    resetComponent() {
      Object.assign(this.$data, this.initialState())
      this.setRightbarContent({
        header: {
          title: 'Schedule',
          buttons: [
            {
              title: 'Add Schedule',
              classes: ['primary-button-right'],
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              { text: 'Total Subject', value: '--' },
              { text: `Todays Subject`, value: '--' },
            ],
          },
        ],
      })
    },

    setDateRange(dateRange) {
      this.dateRange = dateRange
    },

    setDate(date) {
      this.dateRange.startDate = date
    },

    setDayRange({ startDate }) {
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      const date = new Date(startDate)

      this.dayRange.splice(0, 1, days[date.getDay()])

      if (date.getDay() + 6 > 6) {
        this.dayRange.splice(1, 1, days[date.getDay() - 1])
      } else {
        this.dayRange.splice(1, 1, days[date.getDay() + 6])
      }
    },
    /*
     * Load schedules on data monthly, weekly and daily
     * Create query depends on view
     * In Monthly view we  get data by query  filtersby={ to_date:'',from_date:'' }  i.e 1-30
     * In Weekly view we  get data by query  filtersby={ to_date:'',from_date:'' }  i.e 1-7
     * In Day view we  get data by query  filtersby={ to_date:'',from_date:'' }  i.e 12-12
     * Send request by getSchedules to get schedules
     */
    applyFilter() {
      this.forceReRender++

      const startDate = moment(this.dateRange.startDate)
      const endDate = moment(this.dateRange.endDate)

      this.currentDay = startDate.format('dddd')

      this.setDayRange(this.dateRange)

      const fromDate = startDate.format('YYYY-MM-DD')
      const toDate = endDate.format('YYYY-MM-DD')

      // eslint-disable-next-line camelcase
      const filter_by = {}
      filter_by.section_id = this.classId

      if (this.filters.filterBy === GENERAL_CONSTANT.WEEK) {
        filter_by.from_date = fromDate
        filter_by.to_date = toDate
      } else if (this.filters.filterBy === GENERAL_CONSTANT.MONTH) {
        filter_by.from_date = startDate.startOf('month').format(DATE_TIME.DATE)
        filter_by.to_date = startDate.endOf('month').format(DATE_TIME.DATE)
      } else if (this.filters.filterBy === GENERAL_CONSTANT.DAY) {
        filter_by.from_date = fromDate
        filter_by.to_date = fromDate
      }

      if (this.routineFilter.teacher) {
        filter_by.teacher_id = this.routineFilter.teacher
      }

      if (this.routineFilter.subject) {
        filter_by.subject_id = this.routineFilter.subject
      }

      if (this.routineFilter.room) {
        filter_by.room_id = this.routineFilter.room
      }
      this.isLoading = true
      this.filterScheduleForTeachers(filter_by)
        .then((resp) => {
          this.updateRightbarContent(resp)
          this.events = []
          this.respDataObjDump = resp?.data?.records || []
          if (this.filters.filterBy === GENERAL_CONSTANT.MONTH) {
            this.events = resp?.data?.records.map((r) => getSubjectEventUtc(r))
          } else {
            this.events = getEventsWithNonOverlap(resp?.data?.records || [])
          }

          if (this.filters.filterBy === GENERAL_CONSTANT.MONTH && this.events.length > 0) {
            var result = groupBy(this.events, 'date')
            Object.keys(result).forEach((date) => {
              result[date].forEach((att, idx) => {
                if (idx < 3) {
                  att.isAction = false
                } else if (idx === 3) {
                  att.isAction = true
                }
              })
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /*
     * Update rightbar data when we get schedules data from server side
     */
    updateRightbarContent(resp) {
      const rightBar = resp.data.right_bar
      const subjects = resp.data.right_bar.total_subjects_with_detail
      const rightBarData = {
        total_subjects: rightBar.total_subjects,
        today_subjects: rightBar.today_subjects,
      }
      this.rightBarSubjects = [
        {
          title: this.$t('schedule.Subjects'),
          categories: [
            { text: 'Total Subject', value: rightBarData.total_subjects },
            { text: `Todays Subject`, value: rightBarData.today_subjects },
          ],
        },
      ]

      subjects.forEach((subject) => {
        const obj = {
          title: subject.subject_title,
          categories: [
            { text: 'Total Class', value: subject.total_lectures },
            { text: `Completed`, value: subject.completed_lectures },
          ],
          teacherName: subject.section_teacher,
          color: subject.subject_color,
        }
        this.rightBarSubjects.push(obj)
      })
      this.respDataObjDump = resp.data.records
      this.setRightbarContent({
        header: {
          title: 'Schedule',
        },
        stats: this.rightBarSubjects,
      })
    },
    /*
     * Open schedules detail modal contain that day schedules details
     */
    clickDay(date) {
      this.detailsEventsArr = this.events.filter((single) => single.date === date)
      this.routinesDetailModal = true
    },
    /*
     * Open modal contain single schedule details
     */
    singleRecord(record) {
      this.detailsEventsArr = [record]
      this.routinesDetailModal = true
    },
    /*
     * Set filteres data based on filter view i.e Day, Month
     */
    setFilter(filter) {
      this.filters.filterBy = filter
      this.applyFilter()
    },
    /*
     * Reset filterd data and set it to default data
     */
    resetFilter() {
      const fromDate = formatDateString(this.dateRange.startDate)
      const toDate = formatDateString(this.dateRange.endDate)
      const data = {
        section_id: this.classId,
        from_date: fromDate,
        to_date: toDate,
      }
      this.filterScheduleForTeachers(data).then((resp) => {
        this.events = []
        this.respDataObjDump = resp?.data?.records
        this.events = getEventsWithNonOverlap(resp?.data?.records)
        this.routineFilter.teacher = ''
        this.routineFilter.subject = ''
        this.routineFilter.room = ''
      })
    },
    loadFilterData() {
      const query = {
        section_id: this.classId,
      }

      this.loadTeachers(query).then((resp) => {
        this.routineFilterData.teachers = resp.data.map((teacher) => ({
          ...teacher,
          full_name: `${teacher.first_name} ${teacher.last_name}`,
        }))
      })

      this.loadAllRooms()
    },
    /*
     * Get subject from server when create schedule modal open
     * Subjets depends open section
     */
    loadAllSubjects() {
      const query = {
        section_id: this.classId,
        subject_id: this.routineFilter.teacher,
      }

      this.loadSubjects(query).then((resp) => {
        this.routineFilterData.subjects = resp.data
      })
    },
    /*
     * Get rooms from server when create schedule modal open
     * Rooms depends open section
     */
    loadAllRooms() {
      const query = {
        section_id: this.classId,
      }

      this.getRoomsForClass(query).then((resp) => {
        this.routineFilterData.rooms = resp.data
      })
    },

    handleClick(eventType) {
      if (eventType === 'close') {
        this.closeAddNewRoutineModal()
      }
    },

    handleRoutinesDetailClick(eventType) {
      if (eventType === 'close') {
        this.routinesDetailModal = false
      }
    },

    checkSelectedDate(data) {
      const startDate = new Date(data.startDate)
      const endDate = new Date(data.endDate)

      startDate.setDate(startDate.getDate() + 6)

      if (startDate < endDate) {
        this.dateRange.startDate = data.startDate
        this.dateRange.endDate = startDate

        this.$store.commit('toast/NEW', {
          message: this.$t(`toast.Date needs to be within 7 days !`),
        })
      } else {
        this.dateRange.startDate = data.startDate
        this.dateRange.endDate = data.endDate
      }
    },

    selectDateRange() {
      this.setDayRange(this.dateRange)

      let fromDate = new Date(this.dateRange.startDate)
      let toDate = new Date(this.dateRange.endDate)

      fromDate = formatDateString(fromDate)

      toDate = formatDateString(toDate)

      const query = {
        section_id: this.classId,
        from_date: fromDate,
        to_date: toDate,
      }

      this.filterScheduleForTeachers(query).then((resp) => {
        this.events = []
        this.events = getEventsWithNonOverlap(resp?.data?.records || [])
        this.respDataObjDump = resp?.data?.records
      })
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)

        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          allOverlappedEvents = overlappedIds.map((id) => {
            return getSubjectEventUtc(this.respDataObjDump.find((obj) => obj.id === id))
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.routinesDetailModal = true
      }
    },

    editEvent(event) {
      this.getTeachersForSubject(event.subject.subjectId)
        .then(() => {
          this.routineData.id = event.id
          this.routineData.section_id = event.section_id
          this.routineData.subject_id = event.subject.subjectId
          this.routineData.room_id = event.room_id
          this.routineData.teacher_id = event.teacher_id
          this.routineData.day = event.day
          this.routineData.date = event.date
          this.routineData.color = event.color
          this.routineData.start_time = `${event.date}T${event.startTime}`
          this.routineData.end_time = `${event.date}T${event.endTime}`
        })
        .then(() => {
          this.$store.dispatch('layout/setShowModal', true)
        })
    },
    /*
     * Delete Event
     * Get Event as Param and pass its id to delete schedules to delete it
     * Show toast mesaage when event successfully deleted
     */
    deleteEvent(event) {
      const { id } = event
      this.deletePeriod(id).then(() => {
        this.$store.commit('toast/NEW', {
          type: 'success',
          message: this.$t(`toast.Schedule deleted successfully !`),
        })
        this.applyFilter()
      })

      this.viewEvent({})
    },

    getTeachersForSubject(subject) {
      this.routineData.teacher_id = ''
      return new Promise((resolve, reject) => {
        this.getSectionSubjectTeacher(subject).then((resp) => {
          this.routineInfo.teachers = resp.data
          this.routineInfo.teachers.forEach((teacher) => {
            teacher.full_name = teacher.first_name + ' ' + teacher.last_name
          })
          resolve()
        })
      })
    },

    formatDate(start, end) {
      const months = MONTHS
      const startDate = new Date(start)
      const endDate = new Date(end)

      if (startDate.getMonth() === endDate.getMonth()) {
        return `${startDate.getDate()} - ${endDate.getDate()} ${
          months[endDate.getMonth()]
        } ${endDate.getFullYear()}`
      }
      return `${startDate.getDate()} ${months[startDate.getMonth()]} - ${endDate.getDate()} ${
        months[endDate.getMonth()]
      } ${endDate.getFullYear()}`
    },

    closeAddNewRoutineModal() {
      this.clearNewRoutineData()
      this.$refs.createUpdateRoutine.reset()
      this.$store.dispatch('layout/setShowModal', false)
    },

    clearNewRoutineData() {
      this.routineData.day = ''
      this.routineData.section_id = this.classId
      this.routineData.subject_id = null
      this.routineData.teacher_id = null
      this.routineData.room_id = null
      this.routineData.color = null
      this.routineData.start_time = null
      this.routineData.end_time = null
      this.routineData.date = new Date()

      if (this.routineData.id) {
        delete this.routineData.id
      }
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('period', ['getSectionSubjectTeacher']),
    ...mapActions('routine', ['getRoomsForClass', 'loadTeachers', 'loadSubjects', 'loadRooms']),
    ...mapActions('period', ['deletePeriod']),
    ...mapActions('teacher', ['filterScheduleForTeachers']),

    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.vertical-seperator {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.vertical-seperator::before {
  position: absolute;
  top: 15%;
  left: 0;
  width: 1px;
  height: 70%;
  content: '';
  background: var(--primary-grey);
}
</style>

<style lang="scss">
.vs__dropdown-toggle {
  height: inherit;
}

.vs--single.vs--open .vs__selected {
  position: relative !important;
}

.vs__dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
</style>

<style lang="scss">
@import '@src/design/_mediaquery-mixin.scss';
.calenderMobile {
  @include media(null, 640px) {
    .daterangepicker .drp-calendar.right {
      display: none;
    }
  }
}
</style>
